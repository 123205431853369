import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "NodeJS – Trade Rates Service",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-07T18:10:56.000Z",
  "url": "/2019/05/nodejs-trade-rates-service/",
  "categories": ["My Projects"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` One of the main services that provided rate values to all customers graphs on the trading platform. The service had to be fast, and have connection open for streaming any rate change so users could see the graphs get update live. For this mission we use: NodeJS as our programming language and Redis as our data storage. We had two main `}{`“`}{`apps`}{`”`}{` that were running: `}</p>
    <ol>
      <li parentName="ol">{`Listening to rate changes from external APIs`}</li>
      <li parentName="ol">{`Listening for rate changes from the above app and fire that event to all the clients connected to it. To learn more on the company: `}</li>
    </ol>
    <p><a parentName="p" {...{
        "href": "https://spotoption.com/"
      }}>{`SpotOption`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      